export default {
  methods: {
    // 设置选中
    setChecked(ids) {
      // 1.通过node设置
      // this.$refs.tree.setCheckedNodes([
      //   { id: 2, label: "一级 2" },
      //   {
      //     id: 7,
      //     label: "二级 3-1"
      //   }
      // ]);
      // 2.通过key设置
      this.$refs.tree.setCheckedKeys(ids)
    },
    /**
     * 参考：https://blog.csdn.net/maidu_xbd/article/details/104475257
     */
    selectAllNodes() {
      this.parentPositionList.forEach((p) => {
        this.selectAllNodesById(p.id)
      })
    },
    // 全选
    selectAllNodesById: function (rootId) {
      //  获取根节点
      let rootNode = this.$refs.tree.getNode(rootId).parent
      travelNodes(rootNode)
      function travelNodes(tmpRoot) {
        // 选中该节点
        tmpRoot.checked = true
        // 叶子节点
        if (tmpRoot.childNodes.length === 0) {
          return
        }
        // 不是叶子节点,递归遍历
        else {
          let tmpChildNoes = tmpRoot.childNodes
          for (let i = 0; i < tmpChildNoes.length; i++) {
            travelNodes(tmpChildNoes[i])
          }
        }
      }
    },
    // 清空
    clearAllNodes: function () {
      this.$refs.tree.setCheckedKeys([])
    },

    clearAllPrepareNodes() {
      const prepareIds = this.getPrepareIds()

      this.setChecked(this.getChecked().filter(c => !prepareIds.includes(c)))
    },

    selectAllPrepareNodes() {
      const prepareIds = this.getPrepareIds()

      const checked = this.getChecked()
      const setArr = Array.from(new Set([...prepareIds, ...checked])) // 合并
      console.log(setArr, '合并后')

      this.setChecked(setArr)
    },

    // 全选/清空
    //当el-tree设置check-strictly时，通过setCheckedNodes全选方法失效，父子不关联，只能选中一级父节点
    handleSelectAll() {
      if (this.checkAll == false) {
        this.$refs.tree.setCheckedNodes(this.parentPositionList)
        // this.$refs.tree.setCheckedKeys([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
        this.checkAll = true
      } else {
        // this.$refs.tree.setCheckedNodes([]);
        this.$refs.tree.setCheckedKeys([])
        this.checkAll = false
      }
    },

    // 展开收合
    selectChange() {
      this.toggleTree = !this.toggleTree
      const value = this.toggleTree ? 2 : 1
      if (value == 1) {
        // 展开所有
        for (let i = 0; i < this.parentPositionList.length; i++) {
          this.$refs.tree.store.nodesMap[
              this.parentPositionList[i].id
              ].expanded = true
        }
      } else if (value == 2) {
        // 收合所有
        for (let i = 0; i < this.parentPositionList.length; i++) {
          this.$refs.tree.store.nodesMap[
              this.parentPositionList[i].id
              ].expanded = false
        }
      }
    },

    //获取选中
    getChecked() {
      if (!this.$refs.tree) return []

      // 1.通过node获取
      console.log('通过node获取', this.$refs.tree.getCheckedNodes())
      // 2.通过key获取
      console.log('通过key获取', this.$refs.tree.getCheckedKeys())

      // 一级的不要
      const levelOneArr = this.parentPositionList.map((p) => p.id) // 一级是架构，二级才是职务
      return this.$refs.tree
          .getCheckedKeys()
          .filter((k) => !levelOneArr.includes(k))
    },
  }
}