import {positionLevel} from '@/modules/common/api/member-selector'

export default {
  data() {
    return {
      parentPositionList: [], // 组织职务选项数据
      // 联级选择配置
      props: {
        value: 'id',
        label: 'name',
        expandTrigger: 'hover',
        children: 'position',
        multiple: true,
        emitPath: false,
      },
    }
  },
  methods: {
    // 获取架构职务
    getPositionLevel(hash = false) {
      positionLevel({
        hashid_enable: hash ? 1 : 0,
        is_list_tree: 0,
        is_show_all: 0,
      })
        .then((res) => {
          this.parentPositionList = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getPrepareIds() {
      const prepareIds = []
      this.parentPositionList.forEach(p => {
        p[this.props.children].forEach(pp => {
          if (pp.name === '预备会员') {
            prepareIds.push(pp.id)
          }
        })
      })
      console.log(prepareIds, '所有预备会员id')
      return prepareIds;
    },
  },
}
