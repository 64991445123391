<template>
  <div class="term-config">
    <div class="list-content" v-loading="loading">
      <!--
      1.分销可开启/关闭

      2.分销用户可选择指定职务（可多选），所有用户（已登录用户）

      3.返佣比例为数字百分比

      4.入账时间为天数

      5.提现最低金额默认为1
      -->
      <div v-if="config" style="min-height: 400px">
        <div class="config-item">
          <div class="config-title">分销模式</div>
          <div class="config-content">
            <el-form
              class="small-form"
              size="small"
              label-width="70px"
              label-position="left"
            >
              <el-form-item label="分销启用">
                <el-radio-group v-model="config.retail_model.status">
                  <el-radio :label="1">开启</el-radio>
                  <el-radio :label="0">关闭</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="分销用户">
                <div class="flex-col">
                  <el-radio-group v-model="config.retail_model.type">
                    <el-radio
                      v-for="au in authority"
                      :key="au.id"
                      :label="au.id"
                      >{{ au.name }}</el-radio
                    >
                  </el-radio-group>

                  <positions-selector
                    v-show="config.retail_model.type === 0"
                    v-model="config.retail_model.positions"
                  ></positions-selector>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="config-item">
          <div class="config-title">返佣设置</div>
          <div class="config-content">
            <el-form
              class="small-form"
              size="small"
              label-width="70px"
              label-position="left"
              :model="config"
            >
              <el-form-item
                label="返佣比例"
                prop="rebate_config.percent"
                :rules="percentRules"
              >
                <!--                <el-input-number-->
                <!--                  v-model="config.rebate_config.percent"-->
                <!--                  :controls="true"-->
                <!--                ></el-input-number>-->
                <el-input
                  type="number"
                  v-model.number="config.rebate_config.percent"
                  style="max-width: 130px"
                >
                  <span style="padding: 0 5px" slot="suffix">%</span></el-input
                >
                <p class="info">
                  <i class="el-icon-info"></i>订单交易成功后给上级返佣的比例0 -
                  100，例：5 = 返订单商品金额的5%
                </p>
              </el-form-item>
              <el-form-item label="入账时间" prop="rebate_config.time">
                <!--                <el-date-picker type="datetime" style="width: 100%" v-model="config.rebate_config.time"-->
                <!--                                value-format="timestamp" placeholder="请选择">-->
                <!--                </el-date-picker>-->
                <el-input
                  type="number"
                  v-model.number="config.rebate_config.time"
                  style="max-width: 130px"
                >
                  <span style="padding: 0 5px" slot="suffix">天</span></el-input
                >
                <p class="info">
                  <i class="el-icon-info"></i
                  >防止用户退款，佣金被提现了，所以需要设量入账时间（天）。如设置7天无理由退款则建议设置7天
                </p>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="config-item">
          <div class="config-title">提现设置</div>
          <div class="config-content">
            <el-form
              class="small-form"
              size="small"
              label-width="110px"
              label-position="left"
              :rules="rules"
            >
              <el-form-item label="最低提现金额">
                <!--                <el-input-number-->
                <!--                  v-model="config.withdraw_config.min_price"-->
                <!--                  :controls="true"-->
                <!--                ></el-input-number>-->
                <!-- <el-input
                  type="number"
                  v-model.number="config.withdraw_config.min_price"
                  style="max-width: 130px"
                  :min="1"
                >
                  <span style="padding: 0 5px" slot="suffix">元</span></el-input
                > -->
                <div class="flex-align-center">
                  <el-input-number
                    v-model.number="config.withdraw_config.min_price"
                    :min="1"
                  >
                  </el-input-number>
                  <span style="padding: 0 8px">元</span>
                </div>
                <p class="info">
                  <i class="el-icon-info"></i>佣金的值必须大于等于 1 元
                </p>
              </el-form-item>
              <el-form-item label="到账方式" prop="rebate_config.time">
                <el-radio-group :value="1">
                  <el-radio :label="1">线下手动转账</el-radio>
                </el-radio-group>
                <p class="info">
                  <i class="el-icon-info"></i
                  >目前只支持线下手动转账，会员提现后统一进入指定账户，由管理员线下将钱给到提现的会员
                </p>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <fixed-action-bar>
      <el-button type="primary" :loading="loading" @click="saveConfig"
        >保存</el-button
      >
    </fixed-action-bar>
  </div>
</template>

<script>
import FixedActionBar from '../../../base/layout/FixedActionBar'
import { positionLevel } from '../../activity/api/sign-up-list/list'
import { getRetailConfig, setRetailConfig } from '../api/setting'
import PositionsSelector from '../../common/components/PositionsSelector'

export default {
  components: { PositionsSelector, FixedActionBar },
  data() {
    return {
      loading: true,
      rules: {
        // 'rebate_config.time': [
        //   { required: true, message: "请选择入账时间", trigger: "blur" },
        // ]
      },
      /**
       *   "retail_model": {
            "status": 1,
            "type": 1,
            "positions": []
        },
       "rebate_config": {
            "percent": "",
            "time": ""
        },
       "withdraw_config": {
            "min_price": 1
        }
       */
      percentRules: [
        {
          message: '请输入 0 ~ 100 之间的数字',
          validator: (rule, value, callback) => {
            if (value > 0 && value < 100) {
              callback()
            } else {
              callback(new Error('请输入 0 ~ 100 之间的数字'))
            }
          },
        },
      ],
      config: {
        retail_model: {
          status: 1,
          type: 1,
          positions: [],
        },
        rebate_config: {
          percent: '',
          time: '',
        },
        withdraw_config: {
          min_price: 1,
        },
      },
      authority: [
        {
          id: 0,
          name: '指定职务分销',
        },
        {
          id: 1,
          name: '所有用户分销',
        },
      ],
      parentPositionList: [], // 组织职务选项数据
      // 联级选择配置
      props: {
        value: 'id',
        label: 'name',
        expandTrigger: 'hover',
        children: 'position',
        multiple: true,
        emitPath: false,
      },
    }
  },
  watch: {
    isOpenTree(newValue, oldValue) {
      if (newValue) {
        this.treeLoading = true
        this.$nextTick(() => {
          setTimeout(() => {
            this.setChecked(this.config.retail_model.positions)
            this.$nextTick(() => {
              this.treeLoading = false
            })
          }, 500)
        })
      } else {
        console.log(this.getChecked())
        this.config.retail_model.positions = this.getChecked()
      }
    },
  },
  created() {
    this.getConfig()
  },
  methods: {
    parseConfig(config, action = 'get') {
      let _config = JSON.parse(JSON.stringify(config))

      // if (action === 'get') {
      //   if (_config.rebate_config.time) _config.rebate_config.time *= 1000
      // } else {
      //   if (_config.rebate_config.time) _config.rebate_config.time /= 1000
      // }
      return _config
    },
    handleMinusTerm() {
      if (this.config.name < 1) {
        this.config.name = 1
      }
    },
    getConfig() {
      getRetailConfig()
        .then(({ data }) => {
          this.config = this.parseConfig(data)
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    saveConfig() {
      this.loading = true
      setRetailConfig({
        config: JSON.stringify(this.parseConfig(this.config, 'set')),
      })
        .then((res) => {
          if (res.code) this.$message.success(res.msg)
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.term-config {
  .list-item {
    min-height: 100px;
  }

  .config-item + .config-item {
    margin-top: 79px;
  }

  .config-item {
    padding-top: 20px;
    .config-title {
      padding-left: 8px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 16px;
      border-left: 3px #3479ef solid;
    }
    .config-content {
      margin-top: 32px;
      padding-left: 11px;
      .switch-config {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .form-title {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 14px;
          margin-right: 15px;
        }
      }
      .form-tip {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #999;
        line-height: 12px;
        span + span {
          margin-left: 4px;
        }
      }
      .suffix {
        line-height: 34px;
        margin-left: 12px;
        color: rgba(0, 0, 0, 0.85);
      }

      .el-input-number {
        max-width: 140px;
      }
    }
  }

  .flex-col {
    .el-radio {
      line-height: 32px;
    }
  }

  input[type='number'],
  ::v-deep input[type='number'] {
    text-align: center;
  }
}
</style>
