<template>
  <div class="positions-selector flex"
       style="width: 300px; margin-top: 20px;align-items: flex-start">
    <el-cascader
        v-model="positions"
        :options="parentPositionList"
        :props="props"
        placeholder="请选择，可搜索"
        :collapse-tags="true"
        filterable
        clearable
    >
    </el-cascader>
    <el-button @click="isOpenTree = true" type="text" style="margin-left: 10px;"
    >高级设置<i class="el-icon-arrow-right"></i></el-button
    >

    <el-dialog
        :visible.sync="isOpenTree"
        class="dialog-vertical"
        width="600px"
        :title="`已选 ${getChecked().length} 个`"
        close-on-click-modal
        close-on-press-escape
        append-to-body
    >
      <div v-loading="treeLoading">
        <el-button @click="selectChange" type="text">展开/收起</el-button>
        <el-divider direction="vertical" />
        <el-button @click="selectAllNodes" type="text">全选</el-button>
        <el-divider direction="vertical" />
        <el-button @click="clearAllNodes" type="text">全不选</el-button>
        <el-divider direction="vertical" />
        <el-button @click="selectAllPrepareNodes" type="text">全选预备会员</el-button>
        <el-divider direction="vertical" />
        <el-button @click="clearAllPrepareNodes" type="text">全不选预备会员</el-button>
        <el-tree
            ref="tree"
            :data="parentPositionList"
            show-checkbox
            :node-key="props.value"
            :default-expand-all="true"
            :check-strictly="false"
            :props="props"
        >
        </el-tree>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import choosePositions from '../mixins/choosePositions'
import treeHandler from '../mixins/treeHandler'

export default {
  mixins: [choosePositions, treeHandler],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    hash: Boolean
  },
  data() {
    return {
      isOpenTree: false,
      toggleTree: false,
      treeLoading: false,
    }
  },
  computed: {
    positions: {
      get() {
        return JSON.parse(JSON.stringify(this.value));
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    isOpenTree(newValue) {
      if (newValue) {
        this.treeLoading = true
        this.$nextTick(() => {
          setTimeout(() => {
            this.setChecked(this.value)
            this.$nextTick(() => {
              this.treeLoading = false
            })
          }, 500)
        })
      } else {
        console.log(this.getChecked())
        this.positions = this.getChecked()
      }
    },
  },
  created() {
    this.getPositionLevel(!!this.hash)
  },
  methods: {

  },
}
</script>